
<template>

  <div class="section">

    <div class="space"></div>
    <section id="a-propos" class="animation-section">

      <h1 class="primary-title" id="anim-title-apropos">À PROPOS</h1>

      <div class="medium-container">
        <div class="row-custom row-center relative">

          <div class="col-custom">
            <h2 class="secondary-title sr-o">Jolan Callens</h2>
            <nav id="nav-apropos">
              <ul>
                <div class="left">
                  <li class="sr-info">
                    <i class="fas fa-user"></i>
                    <p class="low-title">24 ans</p>
                  </li>
                  <li class="sr-info">
                    <i class="fas fa-home"></i>
                    <p class="low-title">Tahiti</p>
                  </li>
                </div>
                <div class="right">
                  <li class="sr-info">
                    <i class="fas fa-school"></i>
                    <p class="low-title">B3 Developpment Web</p>
                  </li>
                  <li id="dev-el" class="sr-info">
                    <i class="fas fa-hand-holding-heart"></i>
                    <p class="low-title">Dev Web</p>
                  </li>
                </div>
              </ul>
            </nav>
          </div>

          <div class="col-custom relative" id="img-container">
            <h2 class="secondary-title sr-o">Jolan Callens</h2>
            <div class="img"><img id="anim-img" src="@/assets/profil2.png" alt="Photo de Jolan Callens"></div>
          </div>

        </div>
        <p class="paragraphe">Développeur Web en freelance, j’ai passé ces cinq dernières années à enrichir mes compétences dans le domaine de la programmation et plus particulièrement du développement web.</p>
      </div>

    </section>

  </div>

</template>

<script>

export default {
  name: 'Parcours',
  methods: {
    animPhoto: function() {
      const container = document.getElementById('img-container')
      const img = document.getElementById('anim-img')

      // Moving animation
      container.addEventListener('mousemove', (e) => {
        let xAxis = (window.innerWidth / 2 - e.pageX) / 10
        let yAxis = (window.innerHeight / 2 - e.pageY) / 10
        img.style.transform = `rotateY(${xAxis / 2 + 7}deg) rotateX(${yAxis / 2 + 50}deg)`
      })

      // Animate in
      container.addEventListener('mouseenter', () => {
        img.style.transition = 'none'
        img.style.filter = 'drop-shadow(-2px 4px 15px rgba(255,253,220,0.6))'
      })

      // Animation out
      container.addEventListener('mouseleave', () => {
        img.style.transition = '0.8s ease'
        img.style.transform = `rotateY(0deg) rotateX(0deg)`
        img.style.filter = 'drop-shadow(-2px 4px 15px rgba(255,255,255,0.5))'
      })
    }
  },
  mounted: function() {
    this.animPhoto()
  }
}

</script>


<style lang="scss" scoped>
@import '../scss/components/a_propos.scss';

</style>