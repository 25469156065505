<template>
  <div class="real real2">
    <ul class="navbar reveal"></ul>
    <div class="real-infos">
      <h1 class="medium-title medium reveal">Voicy</h1>
      <div class="rea-content">
        <div>
          <div class="line-rea"></div>
          <div class="description-rea reveal">Site Web</div>
          <a href="https://www.voicy.me/" target="_blank" class="button">Voir le projet</a>
        </div>
        <a href="https://www.voicy.me/" target="_blank" class="img-rea reveal"></a>
      </div>
      <div class="primary-title reveal">02</div>
    </div>
  </div>
</template>


<script>


  export default {
    name:'Voicy',
  }

</script>

<style lang="scss">

</style>